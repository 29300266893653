import React, { Component} from "react";
import {hot} from "react-hot-loader"
import "./app.css";
import Login from "./login.js"

class App extends Component{
  render(){
    return(
      <div className="app">
        <Login />
      </div>
    );
  }
}

export default hot(module)(App);